<template>
  <div>
    <div class="module_wrap" style="display: flex; justify-content: center">
      <div style="width: 900px">
        <div class="title" style="margin: 30px 0px 10px 0px">
          <div style="color: #ffffff">
            <div style="font-size: 20px">DIGITAL EXPERT</div>
            <div style="font-size: 40px">SYSTEM</div>
            <div class="picture">
              <div
                v-for="i in colorlist"
                :key="i"
                class="backgroundcolor"
                :style="'background-color: ' + i"
              ></div>
            </div>
          </div>
          <div
            style="
              border-left: 3px solid #ffffff;
              height: 65%;
              margin: 0px 20px;
            "
          ></div>
          <div>
            <img :src="Companyicon" alt="" />
            <div class="title-size">数字专家系统</div>
          </div>
        </div>
        <div class="grid-container">
          <div class="grid-item">
            <div class="grid-item-size">
              <div class="iconfont icon-shengchanshebeiguanli"></div>
              <div>生产设备数字化管理</div>
            </div>
          </div>
          <div class="grid-item">
            <div class="grid-item-size">
              <div class="iconfont icon-donglixitong"></div>
              <div>动力设备数字化管理</div>
            </div>
          </div>
          <div class="grid-item">
            <div class="grid-item-size">
              <div class="iconfont icon-tezhongshebei"></div>
              <div>特种设备数字化管理</div>
            </div>
          </div>
        </div>
        <div class="grid-container" style="margin-top: 20px">
          <div class="grid-item">
            <div class="grid-item-size">
              <div class="iconfont icon-anquanguanlimenhu"></div>
              <div>安全数字化管理</div>
            </div>
          </div>
          <div class="grid-item">
            <div class="grid-item-size">
              <div
                class="iconfont icon-zhiyejiankangguanli_1-6jiankangzhibiaoguanli"
              ></div>
              <div>职业健康数字化管理</div>
            </div>
          </div>
          <div class="grid-item" @click="Environmental('5')">
            <div class="grid-item-size">
              <div class="iconfont icon-icon_huanbaoshuzikanban"></div>
              <div>环保数字化管理</div>
            </div>
          </div>
          <div class="grid-item">
            <div class="grid-item-size">
              <div class="iconfont icon-xiaofangguanli"></div>
              <div>消防数字化管理</div>
            </div>
          </div>
        </div>
        <div
          class="grid-container"
          style="margin-top: 20px; padding-bottom: 50px"
        >
          <div class="grid-item">
            <div class="grid-item-size">
              <div class="iconfont icon-shujuyingyong"></div>
              <div>数据应用管理后台</div>
            </div>
          </div>
          <div class="grid-item" @click="Environmental('9')">
            <div class="grid-item-size">
              <div class="iconfont icon-shuziguanli"></div>
              <div>数字化管理配置后台</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="module_wrap-footer">沪ICP备20020488号-2</div>
  </div>
</template>

<script>
// import Companyicon from "@/assets/Companyicon.png";
export default {
  data() {
    return {
      Companyicon: require("@/assets/Companyicon.png"),
      colorlist: ["chocolate", "#15b053", "#7e7ff5", "#17aefa"],
    };
  },
  methods: {
    Environmental(index) {
      if (index == 5) {
        // 大屏
        window.open("https://www.dgt.net.cn/kzp", "_blank");
      } else if (index == 9) {
        // 环保后台管理
        window.open("https://www.dgt.net.cn/manage", "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.module_wrap {
  height: calc(100vh - 35px);
  min-width: 1000px;
  overflow-y: auto;
  // background-color: #5f6e8e;
}
.module_wrap-footer {
  line-height: 35px;
  height: 35px;
  text-align: center;
  background-color: #ffffff;
}
.title {
  width: 150px;
  height: 150px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  .title-size {
    color: #ffffff;
    font-size: 25px;
  }
  img {
    width: 60px;
    height: 60px;
    padding: 0px 0px 10px 0px;
    margin: 0px auto;
    display: block;
  }
}
.picture {
  display: flex;
  justify-content: space-around;
  padding-top: 3px;
}
.backgroundcolor {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.iconfont {
  text-align: center;
  margin-bottom: 10px;
}
.grid-item {
  flex: 1;
  background-color: hsl(199, 98%, 48%);
  cursor: pointer;
  height: 200px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #0475bb;
  }
}
.grid-container {
  display: flex;
  column-gap: 20px;
}
.grid-item-size {
  font-size: 20px;
}
</style>